import { Redirect } from "react-router-dom";
import useAuth from "../../hooks/useAuth"

export default function RequireAuth({ children }) {
    const { auth } = useAuth();
    return (
        auth?.user
            ? children
            : <Redirect to={'/login'} />
    )
}
