import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import ThemeReducer from "./ThemeReducer";

export const store = configureStore({
  reducer: {
    theme: ThemeReducer.reducer,
  },
  middleware: [thunk],
});
