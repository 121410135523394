import React, { Suspense} from "react";
import "antd/dist/antd.css";
import 'overlayscrollbars/overlayscrollbars.css';
import "./scss/main.css";
import { ThemeProvider } from "styled-components";
import { useSelector } from "react-redux";
import { StyledMainLayout } from "./style-component/Login";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import RequireAuth from "./component/atoms/RequireAuth";

// import Home  from "./pages/Home";
// import Login from "./pages/Login";
const Home = React.lazy(() => import("./pages/Home"));
const Login = React.lazy(() => import("./pages/Login"));

function App() {
  const currentTheme = useSelector(
    (state) => state.theme
  );

  return (
    <ThemeProvider theme={currentTheme}>
      <StyledMainLayout
        className={`${currentTheme.themestyle.type} ${currentTheme.themecolor.colorName} `}
      >
        <Suspense fallback={<p>Loading...</p>}>
          <BrowserRouter>
            <Switch>
              <Route path="/login" exact>
                <Login />
              </Route>
              <Route path="/Home">
                <RequireAuth>
                  <Home />
                </RequireAuth>
              </Route>
              <Route path="/" exact>
                <Redirect to="/Home/courses" />
              </Route>
            </Switch>
          </BrowserRouter>
        </Suspense>
      </StyledMainLayout>
    </ThemeProvider>
  );
}

export default App;
