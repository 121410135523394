import { BLUE, DARK, GREEN, LIGHT, ORANGE, PINK, PURPLE, TEAL } from "./constant";

class THEME {
  constructor(
    type = LIGHT,
    success_bg,
    success_color,
    info_bg,
    info_color,
    warning_bg,
    warning_color,
    error_bg,
    error_color,
    danger_bg,
    danger_bg_secondary,
    bg,

    common_extra_color_4,
    common_extra_color_5,
    common_extra_color_6,
    common_extra_color_7,
    common_extra_color_8,
    font_color,
    shadow_color
  ) {
    this.type = type
    this.success_bg = success_bg;
    this.success_color = success_color;
    this.info_bg = info_bg;
    this.info_color = info_color;
    this.warning_bg = warning_bg;
    this.warning_color = warning_color;
    this.error_bg = error_bg;
    this.error_color = error_color;

    //For danger
    this.danger_bg = danger_bg;
    this.danger_bg_secondary = danger_bg_secondary;

    //General
    this.bg = bg;
    this.common_extra_color_4 = common_extra_color_4;
    this.common_extra_color_5 = common_extra_color_5;
    this.common_extra_color_6 = common_extra_color_6;
    this.common_extra_color_7 = common_extra_color_7;
    this.common_extra_color_8 = common_extra_color_8;
    this.font_color = font_color;
    this.shadow_color = shadow_color;
  };
}

export const DARKTHEME = new THEME(
  DARK,
  "#162312",
  "#49aa19",
  "#111b26",
  "#177ddc",
  "#2b2111",
  "#d89614",
  "#2a1215",
  "#a61d24",
  "#a61d24",
  "#800f19",
  "#161c24",

  "",
  "#161c24",
  "#919EAB",
  "#FFFFFF",
  "#637381",
  "#212b36",
  "",

  "#ffffff",
  "0, 0, 0"
);


export const LIGHTTHEME = new THEME(
  LIGHT,
  "#f6ffed",
  "#52c41a",
  "#e6f7ff",
  "#1890ff",
  "#fffbe6",
  "#faad14",
  "#fff2f0",
  "#ff4d4f",

  //For danger
  "#ff4d4f",
  "#ff7875",

  //General
  "#f7f7f7",
  "#ffffff",
  "#637381",
  "#212B36",
  "#919EAB",
  "#ffffff",

  //Font & Shadow
  "#212b36",
  "145, 158, 171",
)

//Others
export const bordercolor = "#919EAB";
export const common_color_1 = "#ffffff";

export const border_radious_xlg = "1.6rem";
export const border_radious_lg = "1rem";
export const border_radious = "0.8rem";
export const border_radious_sm = "0.4rem";
export const border_radious_zero = "0";


class COLOR {
  constructor(
    primary,
    colorName
  ) {
    this.primary = primary;
    this.colorName = colorName
  };
}

export const BLUECOLOR = new COLOR("#265FD5", BLUE);
export const TEALCOLOR = new COLOR("#00bdaa", TEAL);
export const GREENCOLOR = new COLOR("#00ab55", GREEN);
export const ORANGECOLOR = new COLOR("#fda92c", ORANGE);
export const PURPLECOLOR = new COLOR("#7635dd", PURPLE);
export const PINKCOLOR = new COLOR("#ed5782", PINK);
