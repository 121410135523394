import { createSlice } from "@reduxjs/toolkit";
import { LIGHTTHEME, TEALCOLOR } from "../../model/Theme";

const ThemeSlice = createSlice({
  name: "Theme",
  initialState: {
    themecolor: TEALCOLOR,
    themestyle: LIGHTTHEME
  },

  reducers: {
    UpdateTheme(state, action) {
      return {
        ...state,
        themecolor: action.payload.color,
        themestyle: action.payload.theme,
      };
    },
  },
});


export default ThemeSlice;
export const ThemeActions = ThemeSlice.actions;


